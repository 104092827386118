import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import './Login.css';

export function Login(){
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Replace with your own authentication logic
    if (credentials.username === 'jovmtekari' && credentials.password === 'Jovm6170#') {
      setUser({ username: 'admin' });
      navigate('/admin');
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className="login">
      {/* <video autoPlay loop muted >
        <source src="v-4.mp4" type='video/mp4' />
      </video> */}
      <div className="logi-form ">
      <h2 className='text-white'><span className="bi bi-person"></span> Admin Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <label htmlFor="username" className="form-label text-white">Username</label>
          <input type="text" className="form-control" id="username" name="username" value={credentials.username} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label text-white">Password</label>
          <input type="password" className="form-control" id="password" name="password" value={credentials.password} onChange={handleChange} required />
        </div>
        <button type="submit" className="btn btn-outline-warning">Login</button>
      </form>
      </div>
    </div>
  );
};