
import React from 'react';
import './About.css';

export function About(){
  return (
    <div className="about-container">
      <div className="about-div">
        <h1 className="title-about">About Our School</h1>
        <p className="description mt-5">
          Our school has been providing quality education for over 24+ years. We are dedicated to nurturing our students' academic and personal growth.

Welcome to Jai Onkar Vidya Mandir, where excellence in education meets a nurturing environment. Our dedicated faculty and innovative curriculum ensure that each student reaches their full potential. With state-of-the-art facilities, a wide range of extracurricular activities, and a strong focus on holistic development, we prepare students for success in the modern world. Our community fosters creativity, critical thinking, and lifelong learning. At JOVM, we believe in shaping future leaders who are not only academically proficient but also socially responsible. Join us in creating a brighter future for your child.
        </p>
      </div>

      <div className="why-choose-div mt-5">
        <h2 className="title">Why Choose Our School</h2>
        <ul className="reasons-list list-unstyled justify-content-around mt-5">
          <li className="reason-item bi bi-person-workspace "> Highly qualified teachers</li>
          <li className="reason-item bi bi-easel"> State-of-the-art facilities</li>
          <li className="reason-item bi bi-person-raised-hand"> Strong community support</li>
          <li className="reason-item bi bi-pencil-square"> Comprehensive curriculum</li>
        </ul>
      </div>

      <div className="features-div mt-5">
        <h2 className="title">Our Features</h2>
        <div className="features-list justify-content-around mt-5">
          <div className="feature-item">Modern Laboratories</div>
          <div className="feature-item">Extensive Library</div>
          <div className="feature-item">Sports Complex</div>
          <div className="feature-item">Music and Arts Programs</div>
        </div>
      </div>

      <div className="team-div">
        <h2 className="title">Our Team</h2>
        <div className="team-list mt-5">
          <div className="team-member">
            <img src="mirtynjay.jpg" alt="Team Member" className="team-photo" />
            <p className="team-name">Mrityunjay Kumar - Director</p>
            <p>M•Sc •Zoology B•Ed</p>
          </div>
          <div className="team-member">
            <img src="mrs. mirtynjay.jpg" alt="Team Member" className="team-photo" />
            <p className="team-name">Sunaina Kumari - Principal</p>
            <p>M•A History D•El•Ed</p>
          </div>
        </div>
      </div>


<div className="mb-5">
        <h2 className="title ">Features</h2>
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="feature-box">
              <i className="bi bi-book icon"></i>
              <h4>Extensive Library</h4>
              <p>Our library boasts a vast collection of books and resources to support our students' learning.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-box">
              <i className="bi bi-laptop icon"></i>
              <h4>Modern Classrooms</h4>
              <p>Our classrooms are equipped with the latest technology to enhance the learning experience.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-box">
              <i className="bi bi-dribbble icon"></i>
              <h4>Sports Facilities</h4>
              <p>We offer a range of sports facilities to promote physical fitness and teamwork.</p>
            </div>
          </div>
        </div>
      </div>



      <div className="history-div">
        <h2 className="title">Our History</h2>
        <p className="description mt-5">
          Established in 1999, our school has a rich history of academic excellence and community involvement. We have continuously evolved to meet the needs of our students and society.
        </p>
      </div>
    </div>
  );
};