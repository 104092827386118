import React from 'react';
import './Home.css';
import { About } from '../About/About';

export function Home() {
  return (
    <div className='home'>
      <div className="home-container">
        <video autoPlay loop muted className="video-background">
          <source src="v-7.mp4" type="video/mp4" />
        </video>
        <div className="home-content d-flex justify-content-center align-items-center">
          <div className="text-white text-center fw-bold">
            <h2 className="hero-word">
              <span className='wel'>WELCOME TO</span><br />
              <span className="hero-word-span text-warning">JAI ONKAR VIDYA MANDIR</span>
            </h2>
            <p className='fw-bold'>That is the root of true education</p>
          </div>
        </div>
      </div>
      <section>
        <div><span className="bi bi-facebook"> Facebook</span></div>
        <div><span className="bi bi-twitter"> Twitter</span></div>
        <div><span className="bi bi-instagram"> Instagram</span></div>
        <div><span className="bi bi-youtube"> Youtube</span></div>
        <div><span className="bi bi-linkedin"> LinkedIn</span></div>
      </section>
      <About />
    </div>
  );
}
