import React from 'react';
import { InvoiceForm } from '../InvoiceForm/InvoiceForm';
import { InvoiceSearch } from '../InvoiceSeacrh/FetchInvoice';
import { InvoiceTable } from '../InvoiceTable/InvoiceTable';

export function AdminDashboard(){
  return (
    <div className="container mt-5">
      <h2 className='text-center text-white bg-secondary p-2 '>Admin Dashboard</h2>
      <div className="row">
        <div className="col-md-6">
          <InvoiceForm />
        </div>
        <div className="col-md-6">
          <InvoiceSearch />
        </div>
      </div>
      <hr />
      <div>
        <InvoiceTable />
      </div>
    </div>
  );
};