import './Gallery.css';

export function Gallery() {
    return (
        <div className="gallery-container">
            <div className='gallery d-flex flex-wrap justify-content-between'>
                <img className='g-img' src="g-2.jpg" alt="school pic" />
                <img className='g-img' src="g-3.jpg" alt="school pic" />
                <img className='g-img' src="g-4.jpg" alt="school pic" />
                <img className='g-img' src="g-5.jpg" alt="school pic" />
                <img className='g-img' src="g-6.jpg" alt="school pic" />
                <img className='g-img' src="g-7.jpg" alt="school pic" />
                <img className='g-img' src="g-11.jpg" alt="school pic" />
                <img className='g-img' src="g-12.jpg" alt="school pic" />
                <img className='g-img' src="g-13.jpg" alt="school pic" />
            </div>
        </div>
    );
}
