import React, { useState } from 'react';
import { getInvoice } from '../../api';
import './FetchInvoice.css';

export function InvoiceSearch() {
  const [admissionNumber, setAdmissionNumber] = useState('');
  const [invoice, setInvoice] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const result = await getInvoice(admissionNumber);
      if (result) {
        setInvoice(result);
      } else {
        alert('No invoice found with this serial number.');
      }
    } catch (error) {
      console.error('Error fetching invoice:', error);
      alert('An error occurred while fetching the invoice.');
    }
  };

  return (
    <div className="container fetchInvoice ">
      <h2 className='text-center'>Search Invoice</h2>
      <form onSubmit={handleSearch}>
        <div className="mb-3 input-group w-75">
          <label htmlFor="admissionNumber" className="form-label">Serial Number :</label>
          <input
            type="text"
            className="form-control ms-2"
            id="admissionNumber"
            value={admissionNumber}
            onChange={(e) => setAdmissionNumber(e.target.value)}
            required
          />
        <button type="submit" className=" btn btn-outline-info">Search</button>
        </div>
      </form>
      {invoice && (
        <div className="mt-5 border-2 border-info p-3">
          <h3>Invoice Details</h3>
          <p><strong>Serial Number:</strong> {invoice.admission_number}</p>
          <p><strong>Date:</strong> {invoice.date}</p>
          <p><strong>Student Name:</strong> {invoice.student_name}</p>
          <p><strong>Roll Number:</strong> {invoice.roll_no}</p>
          <p><strong>Class:</strong> {invoice.class_name}</p>
          <p><strong>Section:</strong> {invoice.section}</p>
          <p><strong>Other:</strong> {invoice.other}</p>
          <p><strong>Total:</strong> {invoice.total}</p>
          <p><strong>Signature:</strong> {invoice.signature}</p>
          <p>
            <strong>View PDF:</strong> 
            <a href={`https://jovmtekari.online/backend/${invoice.pdf_path}`} target="_blank" rel="noopener noreferrer">
              <span className="bi bi-eye text-info"></span> View PDF
            </a>
          </p>
        </div>
      )}
    </div>
  );
}
