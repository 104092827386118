import React from 'react';
import './Footer.css';

export function Footer() {
  return (
    <div className="footerr">
      <footer className="footer bg-dark text-white pt-4 pb-2">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-3">
            <h5 className="mb-3">Contact Us :</h5>
            <p>ANDER KILA TEKARI, GAYA, BIHAR, 824236</p>
            <p>Phone : <a className='text-decoration-none text-white' href="tel:+919955836170">+91 9955836170</a></p>
            <p>Email : <a className='text-decoration-none text-white' href="mailto:info@jovmtekari.online">info@jovmtekari.online</a></p>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <h5 className="mb-3">Follow Us :</h5>
            <div className="social-links">
              <a href="https://facebook.com" className="text-white mr-3">
                <i className="fab fa-facebook-f text-dark p-2"></i>
              </a>
              <a href="https://twitter.com" className="text-white mr-3">
                <i className="fab fa-twitter text-dark p-2"></i>
              </a>
              <a href="https://instagram.com" className="text-white mr-3">
                <i className="fab fa-instagram text-dark p-2"></i>
              </a>
              <a href="https://linkedin.com" className="text-white">
                <i className="fab fa-linkedin-in text-dark p-2"></i>
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <h5 className="mb-3">School Info :</h5>
            <p>Why Choose Our School</p>
            <p>Programs & Courses</p>
            <p>Admission Process</p>
          </div>
        </div>
        <div className="footer-copy d-flex flex-column flex-md-row justify-content-between mt-3">
          <p className="mb-0">&copy; 2024 JOVM Tekari. All rights reserved.</p>
          <p>Design & Developed by <a className='text-decoration-none' href="https://www.mountgit.com">MountGit</a></p>
        </div>
      </div>
    </footer>
    </div>
  );
}
