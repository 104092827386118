// import React, { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { Navbar } from './components/Navbar/Navbar';
// import { Home } from './components/Home/Home';
// import Login from './components/Login/Login';
// import { Gallery } from './components/Gallery/Gallery';
// import { Footer } from './components/Footer/Footer';
// import { About } from './components/About/About';

// export function App(){
//   return(
//   <BrowserRouter>
//  <div>
//   <Navbar />
 
//   <Routes>
//     <Route path='/' element={<Home />} />
//     <Route path='/about' element={<About />} />
//     <Route path='gallery' element={<Gallery />} />
//     <Route path='login' element={<Login />} />
//   </Routes>
  
//   <Footer />
//  </div>
//   </BrowserRouter>
//   )
// }







import React, { useContext } from 'react';
import './Styles/colors.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Navbar } from './components/Navbar/Navbar';
import { Home } from './components/Home/Home';
import { Gallery } from './components/Gallery/Gallery';
import { Footer } from './components/Footer/Footer';
import { About } from './components/About/About';
import { AppContext, AppProvider } from './AppContext';
import { Login } from './components/Login/Login';
import { AdminDashboard } from './components/AdminDashboard/AdminDashboard';

function PrivateRoute({ element: Element, ...rest }) {
  const { user } = useContext(AppContext);
  return user ? <Element {...rest} /> : <Navigate to="/login" />;
}

export function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<PrivateRoute element={AdminDashboard} />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </AppProvider>
  );
}
