import React, { useState } from "react";
import axios from "axios";
import "./InvoiceForm.css";

export function InvoiceForm() {
  // Get the current date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    admission_number: "",
    date: today, // Set the date to current date
    student_name: "",
    roll_no: "",
    class_name: "",
    section: "",
    other: "",
    total: "",
    signature: "",
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await axios.post('https://jovmtekari.online/backend/create_invoice.php', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        alert('Invoice created successfully!');
        setFormData({
          admission_number: '',
          date: today, // Reset date to current date
          student_name: '',
          roll_no: '',
          class_name: '',
          section: '',
          other: '',
          total: '',
          signature: ''
        });
      } else {
        setError(response.data.message || 'Unknown error occurred');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred');
      } else {
        console.error('Error creating invoice:', error);
        alert('An error occurred while creating the invoice.');
      }
    }
  };

  return (
    <div className="container form-style">
      <h2 className="text-center">Create Invoice</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="admission_number" className="form-label">
            Serial Number
          </label>
          <input
            type="text"
            className="form-control"
            id="admission_number"
            name="admission_number"
            value={formData.admission_number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Date
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            min={today} // Ensure that the date input value is today
            max={today} // Ensure that the date input value is today
            readOnly // Prevent user from changing the date
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="student_name" className="form-label">
            Student Name
          </label>
          <input
            type="text"
            className="form-control"
            id="student_name"
            name="student_name"
            value={formData.student_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="roll_no" className="form-label">
            Roll Number
          </label>
          <input
            type="text"
            className="form-control"
            id="roll_no"
            name="roll_no"
            value={formData.roll_no}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="class_name" className="form-label">
            Class
          </label>
          <input
            type="text"
            className="form-control"
            id="class_name"
            name="class_name"
            value={formData.class_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="section" className="form-label">
            Section
          </label>
          <input
            type="text"
            className="form-control"
            id="section"
            name="section"
            value={formData.section}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="other" className="form-label">
            Other Details
          </label>
          <textarea
            className="form-control"
            id="other"
            name="other"
            rows="3"
            value={formData.other}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="total" className="form-label">
            Total
          </label>
          <input
            type="number"
            className="form-control"
            id="total"
            name="total"
            value={formData.total}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="signature" className="form-label">
            Signature
          </label>
          <input
            type="text"
            className="form-control"
            id="signature"
            name="signature"
            value={formData.signature}
            placeholder="After print, sign with pen"
            onChange={handleChange}
            readOnly
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button type="submit" className="btn btn-outline-info w-50 mb-2">
          Create Invoice
        </button>
      </form>
    </div>
  );
}