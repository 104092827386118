// export const createInvoice = async (invoiceData) => {
//   const response = await fetch('https://jovmtekari.online/backend/create_invoice.php', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(invoiceData),
//   });
//   const result = await response.json();
//   return result;
// };

// export const getInvoice = async (admissionNumber) => {
//   const response = await fetch(`https://jovmtekari.online/backend/get_invoice.php?admission_number=${admissionNumber}`);
//   const result = await response.json();
//   return result;
// };

// export const getAllInvoices = async () => {
//   const response = await fetch('https://jovmtekari.online/backend/getAllInvoices.php');
//   const result = await response.json();
//   return result;
// };






export const createInvoice = async (invoiceData) => {
  try {
    const response = await fetch('https://jovmtekari.online/backend/create_invoice.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(invoiceData),
    });
    if (!response.ok) {
      throw new Error(`Error creating invoice: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Create Invoice Error:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const getInvoice = async (admissionNumber) => {
  try {
    const response = await fetch(`https://jovmtekari.online/backend/get_invoice.php?admission_number=${admissionNumber}`);
    if (!response.ok) {
      throw new Error(`Error fetching invoice: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Get Invoice Error:', error);
    throw error;
  }
};

export const getAllInvoices = async () => {
  try {
    const response = await fetch('https://jovmtekari.online/backend/getAllInvoices.php');
    if (!response.ok) {
      throw new Error(`Error fetching all invoices: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Get All Invoices Error:', error);
    throw error;
  }
};